/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { setupLayouts } from 'virtual:generated-layouts'
//import { useAuthStore } from "@/store/auth";
//import { storeToRefs } from 'pinia'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes: setupLayouts,
})

/*
const storeRef = storeToRefs(useAuthStore())
router.beforeEach((to, from, next) => {
  console.log(to.path)
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  //const loggedIn = localStorage.getItem('user');

  if (authRequired){// && !storeRef.isLogined) {
    next('/login');
  } else {
    next();
  }
});
 */

export default router

