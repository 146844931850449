export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/actionManagement',
    name: '/actionManagement',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/actionManagement.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/actionManagementAll',
    name: '/actionManagementAll',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/actionManagementAll.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/actionManagementList',
    name: '/actionManagementList',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/actionManagementList.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/actionManagementListDetail',
    name: '/actionManagementListDetail',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/actionManagementListDetail.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/customerManagement',
    name: '/customerManagement',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/customerManagement.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnb"
    }
  },
  {
    path: '/groupManagement',
    name: '/groupManagement',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/groupManagement.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnb"
    }
  },
  {
    path: '/languageSetting',
    name: '/languageSetting',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/languageSetting.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "login"
    }
  },
  {
    path: '/main',
    name: '/main',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/main.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/monitoring',
    name: '/monitoring',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/monitoring.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/monitoringManagementList',
    name: '/monitoringManagementList',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/monitoringManagementList.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/monitoringManagementListDetail',
    name: '/monitoringManagementListDetail',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/monitoringManagementListDetail.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/myBoard',
    name: '/myBoard',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/myBoard.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/myBoardDetail',
    name: '/myBoardDetail',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/myBoardDetail.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/organizationalManagement',
    name: '/organizationalManagement',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/organizationalManagement.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/safetyLeadership',
    name: '/safetyLeadership',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/safetyLeadership.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/safetyLeadershipAMList',
    name: '/safetyLeadershipAMList',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/safetyLeadershipAMList.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/safetyLeadershipAMListDetail',
    name: '/safetyLeadershipAMListDetail',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/safetyLeadershipAMListDetail.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/safetyLeadershipManagement',
    name: '/safetyLeadershipManagement',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/safetyLeadershipManagement.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/safetyLeadershipManagementDetail',
    name: '/safetyLeadershipManagementDetail',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/safetyLeadershipManagementDetail.vue'),
    /* no children */
    meta: {
      "layout": "layoutGnbLnb"
    }
  },
  {
    path: '/test',
    name: '/test',
    component: () => import('E:/dev/intellij ws/impactus3_frontend/frontend/src/pages/test.vue'),
    /* no children */
    meta: {
      "layout": "layoutTest"
    }
  }
]
